@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;900&display=swap');

body {
    font-family: 'Inter', sans-serif;
}

code {
    font-family: 'Inter', sans-serif;
}

/* Show recaptcha badge only on step 3 */
.grecaptcha-badge {
    visibility: hidden;
}
.contact_form .grecaptcha-badge {
    visibility: visible;
}
